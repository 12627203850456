





























import { Component, Vue, Prop } from 'vue-property-decorator';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { Question as IQuestion } from '@/globalInterfaces/Questions';

@Component({})
export default class QuestionItem extends Vue {
  @Prop({ required: true }) question!: IQuestion;
  @Prop({ required: true }) localTrack!: string;

  private setModal = new ActiveModal();

  handleClickQuestion(questionId: number) {
    this.setTrackAmplitude();

    this.$emit('question', questionId);
  }

  setShowModal() {
    this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'QuestionItem',
          local: this.localTrack,
          questionID: this.question?.ID
        }
      }
    });
  }
}
