





































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { IOption } from '@/globalInterfaces/Questions';

import { MAXIMUM_FONT_SIZE, MINIMUM_FONT_SIZE } from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeSummation extends Vue {
  @Prop({ required: true }) alternatives!: Array<IOption>;
  @Prop({ default: 14 }) fontSizeProp!: number;

  private summationAnswer = '0' as null | string;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  get summationAlternatives() {
    let total = 0;

    this.alternatives.forEach((alternative, index) => {
      if (alternative.validated === 1) {
        let points = 0;

        if (index === 0 || index === 1) points = index + 1;
        else points = 2 ** index;

        total += points;
      }
    });

    return total;
  }

  @Watch('alternatives', { immediate: true })
  setShuffledAlternatives() {
    const alternativesWithIndex = this.alternatives.map((alternative, index) => ({
      ...alternative,
      index: this.setAlternativeNumberSummation(index)
    }));

    this.$store.commit('setShuffledAlternatives', alternativesWithIndex);
  }

  @Watch('summationAnswer')
  setValue(newVal: string) {
    if (Number(newVal) < 0) {
      this.summationAnswer = '0';
    }

    this.$store.commit('setSummationQuestion', {
      answer: this.summationAnswer ? Number(this.summationAnswer) : null,
      rightAnswer: this.summationAlternatives
    });
  }

  setAlternativeNumberSummation(position: number) {
    if (position === 0 || position === 1) return position + 1;

    return 2 ** position;
  }
}
