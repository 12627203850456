











































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { IOption, INewAlternativesTrueOrFalse } from '@/globalInterfaces/Questions';

import { MAXIMUM_FONT_SIZE, MINIMUM_FONT_SIZE } from '@/constant/Question';

@Component({
  components: {
    Arrow,
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeTruthyOrFalsy extends Vue {
  @Prop({ required: true }) alternatives!: Array<IOption>;
  @Prop({ default: 14 }) fontSizeProp!: number;

  private newAlternatives: Array<INewAlternativesTrueOrFalse> = [];

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  @Watch('alternatives', {
    immediate: true
  })
  setAlternatives(): void {
    if (this.alternatives && this.alternatives.length) {
      this.newAlternatives = this.alternatives.map((alternative: IOption) => ({
        ...alternative,
        selectedOption: null
      }));
    }
  }

  emitSelections() {
    this.$emit('selections-updated', this.newAlternatives);
  }
}
