


















import { Component, Vue } from 'vue-property-decorator';

import Problem from '@/assets/icons/Problem.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

@Component({
  components: {
    Problem
  }
})
export default class ButtonReportProblem extends Vue {
  private isActiveProblem = false;

  private setModal = new ActiveModal();

  mounted() {
    const $ReportModal = document.getElementById('modalReportProblem');
    // @ts-ignore
    $ReportModal.addEventListener('hidden.bs.modal', () => {
      this.isActiveProblem = false;
    });
  }

  handleClickReportProblem() {
    this.setModal.activeModal('modalReportProblem');
    this.setTrackAmplitude();

    this.isActiveProblem = !this.isActiveProblem;
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ButtonReportProblem',
          local: 'Reportar problema'
        }
      }
    });
  }
}
