




















import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import MapQuestionLoading from '../../MapQuestions/MapQuestionLoading.vue';

@Component({
  components: {
    ExLoading,
    MapQuestionLoading
  }
})
export default class HelpResolutionLoading extends Vue {}
