





















import { Component, Vue, Watch } from 'vue-property-decorator';

import Movie from '@/assets/icons/Movie.vue';

@Component({
  components: {
    Movie
  }
})
export default class ButtonMovie extends Vue {
  private isActiveMovie = false;

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  @Watch('$store.getters.isMovie')
  setMovieClick(): void {
    if (!this.$store.getters.isMovie && this.isActiveMovie) {
      this.isActiveMovie = !this.isActiveMovie;
    }
  }

  movieClick(): void {
    this.isActiveMovie = !this.isActiveMovie;
    this.$store.commit('setIsMovie', !this.$store.getters.isMovie);
  }
}
