














import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { throttle } from 'lodash';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import HistoricService from '@/services/Historic/historicService';

@Component({
  components: {
    vueVimeoPlayer
  }
})
export default class PlayerVideoVimeo extends Vue {
  @Prop({ required: true }) file!: Record<string, any>;
  @Prop() idTopic!: number;
  @Prop() context!: string;
  @Prop() path!: string;
  @Prop() titleTopic!: string;
  @Prop() titleBook!: string;
  @Prop() replay!: boolean;
  @Prop() isChecked!: boolean;
  @Prop({ default: false }) restart!: boolean;

  private time = 0;
  private percent = 0;
  private duration = 0;
  private timeIntervalOrder = 0;
  private quantityClickBtnPlay = 0;

  private nextVideo: boolean | null = null;

  private player: any;

  private historicFile: Array<Record<string, any>> = [];

  private historicService = new HistoricService();

  private timeInterval = throttle(() => {
    this.setHistoric();
  }, 15000);

  async mounted() {
    await this.setVideo();
  }

  destroyed() {
    this.destroyVideo();
    this.player.destroy();
  }

  destroyVideo() {
    clearInterval(this.timeIntervalOrder);
  }

  get vimeoId() {
    let Id = null;
    if (this.file && this.file.media) {
      const mediaSplited = this.file.media.split('?');
      Id = mediaSplited[0] || null;
    }
    return Id;
  }

  @Watch('file')
  async setVideo() {
    this.destroyVideo();
    this.quantityClickBtnPlay = 0;
    this.newVideo();
    this.emitPlayVideo();
  }

  async newVideo() {
    const getHistoricVideo = (await this.historicService.getHistoric({
      type: 'video',
      listIds: [this.file.idVideo]
    })) || [];

    this.historicFile = getHistoricVideo;

    if (this.player) {
      this.setMessageVideo();
      let duration = 0;

      if (!this.restart && this.historicFile[0] && this.historicFile[0].percent) {
        duration = (this.file.time * this.historicFile[0].percent) / 100;
      }

      this.player.setCurrentTime(duration);
    }

    this.timeIntervalOrder = setInterval(this.setTime, 1000);
  }

  emitPlayVideo() {
    this.$emit('play-video');
  }

  error() {
    this.$emit('removeMessageVideo');
    this.$emit('setMessageVideo', 'error');
  }

  ready(video: Record<string, any>) {
    this.player = video;
    let duration = 0;
    this.setMessageVideo();

    if (!this.restart && this.historicFile[0] && this.historicFile[0].percent) {
      duration = (this.file.time * this.historicFile[0].percent) / 100;
    }
    this.player.setCurrentTime(duration);

    this.setTrackAmplitude();
  }

  play() {
    this.bntClickForNextVideo();
  }

  ended() {
    this.percent = 100;
    this.setHistoric();
  }

  setHistoric() {
    try {
      if (!this.time) return;

      const videoDate = {
        idobject: this.file.idVideo,
        type: 'video',
        percent: this.percent,
        checked: this.isChecked,
        object: {
          thumb:
            this.file.detail?.thumb?.length
            && this.file.detail?.thumb[3]
            && this.file.detail?.thumb[3].link,
          slug: this.file.slug,
          idTopic: String(this?.idTopic),
          title: this.file.title,
          titleTopic: this.titleTopic,
          titleBook: this.titleBook,
          time: this.file.time,
          book: this.path,
          context: this.context,
          path: this.path
        }
      };

      this.historicService.saveHistoric(videoDate);

      this.$store.commit('setOrder', { video: this.percent, time: this.time });
    } catch (error) {
      console.error(error);
    }
  }

  async setTime() {
    if (!this.player) return;

    this.time = await this.player.getCurrentTime();
    this.duration = await this.player.getDuration();
    this.percent = (this.time * 100) / this.duration;

    this.$store.commit('setOrder', { video: this.percent, time: this.time });
  }

  setMessageVideo() {
    if (
      this.historicFile[0]
      && this.historicFile[0].percent
      && this.historicFile[0].percent === 100
    ) {
      this.nextVideo = false;
      this.$emit('setMessageVideo', 'replay');
    } else if (
      this.historicFile[0]
      && this.historicFile[0].checked
      && this.historicFile[0].percent !== 100
    ) {
      this.$emit('setMessageVideo', 'continue');
    } else {
      this.nextVideo = true;
    }
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PlayerVideoVimeo',
          local: 'Player Vídeo'
        }
      }
    });
  }

  bntClickForNextVideo() {
    // this.$emit('video-viewed', this.percent);

    this.quantityClickBtnPlay += 1;

    if (this.quantityClickBtnPlay >= 2) {
      this.nextVideo = true;
    }
  }

  @Watch('percent')
  setEmitPercent() {
    if (this.nextVideo && this.percent && this.percent === 100) {
      this.$emit('nextVideo');
    }
  }

  @Watch('replay')
  replayVideo() {
    if (this.player) this.player.play();
  }
}
