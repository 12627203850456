
























































import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import Eye from '@/assets/icons/Eye.vue';
import EyeSlash from '@/assets/icons/EyeSlash.vue';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { MAXIMUM_FONT_SIZE, MINIMUM_FONT_SIZE } from '@/constant/Question';

@Component({
  components: {
    Eye,
    EyeSlash,
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeObjective extends Vue {
  @Prop({ required: true }) alternatives!: Array<Record<string, any>>;
  @Prop({ required: true }) id!: number;
  @Prop({ default: 0 }) index!: number;
  @Prop({ default: 14 }) fontSizeProp!: number;

  private isHide = true;
  private newAlternatives: Array<Record<string, any>> = [
    {
      title: '',
      value: false,
      isAble: true
    }
  ];

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  @Watch('alternatives', {
    immediate: true
  })
  setAlternatives(): void {
    if (this.alternatives && this.alternatives.length) {
      this.newAlternatives = this.alternatives.map((alternative: object) => ({
        ...alternative,
        isAble: true
      }));
    }
  }

  hideEye(index: number): void {
    const alternatives = this.newAlternatives;

    alternatives[index].isAble = !alternatives[index]?.isAble;
    this.newAlternatives = alternatives;
  }

  setValue(indexAlternative: number, idQuestion: number, indexQuestion: number, idOption: number) {
    this.$emit(
      'selected-answer-in-question',
      indexAlternative,
      idQuestion,
      indexQuestion,
      idOption
    );
  }
}
