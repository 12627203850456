

































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component
export default class Arrow extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: 'Esconder' }) title!: string;
}
